.home-page-title-image {
    background-position:  center;
    background-size: cover;
    width: 100%;
    height: 85vh;
}
.home-page-title-row {
    background-color: var(--banner-color);
    text-align: center;
    width: 100%;
    margin: 0;
    height: 15vh;
}
.home-page-title-col-text h3 {
    color: var(--white);
    vertical-align: middle;
}

.home-page-title-col-text{
    margin: 0 auto;
    height: 10vh;
    display:flex;
    align-items: center;
    justify-content: center;
}

.home-page-title-col-chevron{
    height: 5vh;
}

.home-page-nomination-search-row {
    width: 100%;
    margin: 0;
}

.home-page-search-box {
    width: 100%;
    text-align: center;
    padding: 5vh 0;
    border-radius: 1vh;
}
.home-page-search-box p, .home-page-search-box h3 {
    color: var(--black);
}

.home-page-nominations, .home-page-search-row {
    margin-bottom: 10vh;
}
.card-title {
    text-align: center;
    color: var(--black);
}
.home-page-nominations h3 {
    text-align: center;
}
