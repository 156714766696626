.nomination-side-bar-content h3{
    text-align: center;
}

.nomination-side-bar-content h6 {
    text-align: center;
}
.nomination-side-bar-content .row {
    width: 100%;
}

.item-exit {
    opacity: 1;
}
.item-exit-active {
    transform: translate(30vh);
    transition: all 300ms ease-in;
}