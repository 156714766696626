.search-results-pagination {
    margin: 0 auto;
    width: fit-content;
}

.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.pagination li {
    display: inline-block;
}