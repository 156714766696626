.nomination-cards p {
    text-align: center;
}

.card-deck {
    margin: 2vh;
}

.card-img-top {
    width: 100%;
    height: 55vh;
    object-fit: cover;
    object-position: top;
}

.empty-card {
    border: none;
}