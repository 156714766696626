.pageHeader {
    z-index: 2;
    padding: 0;
}

.pageHeaderRow{
    color: var(--white);
    padding: 2vh;
    width:100%;
    height: 8vh;
}

.pageHeaderRow h2 {
    color: var(--white);
    text-align: left;
    font-family: 'Lobster', cursive;
}
.page-navigation-title {
    width: fit-content;
    padding-left: 2vh;
}

.page-navigation-title h2 {
    font-size: 3vh;
    margin: 0;
    display: flex;
    align-content: center;
}

.fa-trophy {
    color: var(--white);
    height: 100%;
    vertical-align: middle;
    font-size: 3vh;
}

.ant-badge {
    height: 100%;
}
.ant-badge p{
    color: var(--white);
}

.alert-navbar {
    z-index: 1;
    margin-top: 8vh;
    padding: 0;
}

.alert-navbar .alert{
    width: 100%;
    border-radius: 0;
}

.fa-trophy:hover {
    cursor:pointer;
}

.animated, .slideInDown, .slideOutUp{
    width: 100%;
}

.page-navigation-search {
    display:flex;
    align-items: center;
    justify-content: center;
}

.page-navigation-search h6 {
    color: var(--white);
    text-align: right;
    margin: 0;
    font-size: 2vh;
}

.alert-transition-enter{
    transform: translateY(-100%);
}
.alert-transition-exit{
    transform: translateY(0%);
}
.alert-transition-enter-active{
    transform: translateY(0%);
}
.alert-transition-exit-active{
    transform: translateY(-100%);
}
.alert-transition-enter-active,
.alert-transition-exit-active{
    transition: transform 500ms ease-in;
}